<template>
	<div class="pui-form">
		<partidaequipamiento-modals
			:modelName="modelName"
		></partidaequipamiento-modals>		
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
            <v-flex xs12 md12>
				<pui-field-set :title="$t('partida.field.datos')">
					<v-layout wrap>
						<!-- IDPAREQUIPO -->			
						<v-flex xs12 md1>
							<pui-text-field
								:id="`idparequipo-partidaequipamiento`"
									v-model="model.idparequipo"
								:label="$t('partidaequipamiento.idparequipo')"
								disabled								
								toplabel
								maxlength="10"
							></pui-text-field>
						</v-flex>						
					</v-layout>
					<v-layout wrap>					
						<!-- IDPARTIDA -->
						<v-flex xs12 md3>							
							<pui-select
								id="idpartida"
								attach="idpartida"
								:label="$t('partidaequipamiento.idpartida')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppartida"
								:itemsToSelect="[{ id: model.idpartida }]"
								:modelFormMapping="{ id: 'idpartida' }"
								itemValue="id"
								:itemText="item => `${item.id} - ${item.numpartida} - ${item.numbultos}`"
								:order="{ numpartida: 'asc' }"
							></pui-select>
						</v-flex>
						<!-- IDNOTIFEQUIP -->
						<v-flex xs12 md3>							
							<pui-select
								id="idnotifequip"
								attach="idnotifequip"
								:label="$t('partidaequipamiento.idnotifequip')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vlupequipamiento"
								:itemsToSelect="[{ id: model.idnotifequip }]"
								:modelFormMapping="{ id: 'idnotifequip' }"
								itemValue="id"
								:itemText="item => `${item.id} - ${item.matricula} - ${item.pesobruto}`"
								:order="{ matricula: 'asc' }"
							></pui-select>							
						</v-flex>
					</v-layout>
					
					<v-layout wrap>	
						
					</v-layout>
					<v-layout wrap>	
						<!-- NUMBULTOS -->
						<v-flex xs12 md3>						
							<pui-text-field
								:id="`numbultos-partidaequipamiento`"
								v-model="model.numbultos"
								:label="$t('partidaequipamiento.numbultos')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="8"
							></pui-text-field>
						</v-flex>					
						<!-- LUGARESTIBA -->
						<v-flex xs12 md3>						
							<pui-text-field
								:id="`lugarestiba-partidaequipamiento`"
								v-model="model.lugarestiba"
								:label="$t('partidaequipamiento.lugarestiba')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="25"
							></pui-text-field>
						</v-flex>
						<!-- PESONETO -->
						<v-flex xs12 md3>						
							<pui-text-field
								:id="`pesoneto-partidaequipamiento`"
								v-model="model.pesoneto"
								:label="$t('partidaequipamiento.pesoneto')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="18"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>	
						
					</v-layout>									
				</pui-field-set>
				<!-- MINI AUDIT -->
                <pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
			</v-flex>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading
			v-else
		></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import partidaequipamientoActions from './PartidaequipamientoActions';
import partidaequipamientoModals from './PartidaequipamientoModals.vue';

export default {
	name: 'partidaequipamiento-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'partidaequipamiento-modals': partidaequipamientoModals
	},
	data() {
		return {
			modelName: 'partidaequipamiento'
			, actions: partidaequipamientoActions.formActions
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {
	},
	created() {
	}
}
</script>
